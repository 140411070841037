import React from "react"
import { Link } from "gatsby"
import { Button, Jumbotron } from "react-bootstrap"

export default ({
  title,
  message,
  redirectBack,
  doAskLogout,
  customButtons,
}) => {
  return (
    <Jumbotron className="mb-0">
      {title ? <h1 className="display-4">{title}</h1> : null}
      <p className="lead">{message || "Something went wrong!"}</p>

      {redirectBack ? (
        <Button variant="danger" block className="btn-d-border">
          <Link
            to={redirectBack}
            state={{
              redirectFrom: "/404",
            }}
          >
            Redirect Back
          </Link>
        </Button>
      ) : null}

      {doAskLogout && doAskLogout === true ? (
        <Button variant="warning" block className="btn-d-border">
          <Link to={`/logout`}>Logout</Link>
        </Button>
      ) : null}

      {customButtons && customButtons.length
        ? customButtons.map(customButton => (
            <>
              {Object.keys(customButton).length &&
              (customButton || {}).url &&
              (customButton || {}).label ? (
                <Button
                  variant={(customButton || {}).color || "secondary"}
                  block
                  className="btn-d-border"
                >
                  <Link to={`${customButton.url}`}>{customButton.label}</Link>
                </Button>
              ) : null}
            </>
          ))
        : null}
    </Jumbotron>
  )
}
