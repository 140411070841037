import React from "react"
import { Router } from "@reach/router"

import SEO from "../components/seo"
import JobSingle from "../components/partials/job-single"
import Loading from "../components/partials/loading"

import withState from "../util/withState"

const Jobs = ({ state }) => {
  const { apiURL } = state
  return (
    <>
      <SEO title="Job Single Title" keywords={[`mazrui`, `careers`]}/>
      <Router basepath="/jobs">
        <JobSingle
          path="/:id"
          endpoint={`${apiURL}/vacancies` || undefined}
        />
        <Loading path="/"/>
      </Router>
    </>
  )
}

export default withState(Jobs)
