import React, { useState, useEffect, useCallback } from "react"
import Axios from "axios"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Badge,
} from "react-bootstrap"

import ApplicantForm from "./job-apply"
import withState from "../../util/withState"
import Error from "./error"
import Loading from "./loading"
import SEO from "../seo"
import { Link } from "gatsby"

const JobSingle = ({ id: jobId, state, location, endpoint }) => {
  const dataPassed = ((location || {}).state || {}).data
  const [isOpen, openModal] = useState(false)
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(dataPassed || {})
  const [isApplied, setIsApplied] = useState({
    status: false,
    on: undefined,
  })

  const handleClose = () => {
    isUserApplied()
    openModal(false)
  }
  const handleOpen = () => openModal(true)

  const { state: stateData, checkLogin, apiURL } = state

  const currentUserId = ((stateData || {}).user || {}).id

  const isUserApplied = useCallback(() => {
    if (checkLogin() === true && currentUserId) {
      Axios({
        method: "get",
        url: `${apiURL}/meta/isapplied/${jobId}`,
        params: {},
      }).then(({ data }) => {
        setIsApplied(data)
      })
    }
  }, [jobId, currentUserId, apiURL, checkLogin])

  useEffect(() => {
    if (!((location || {}).state || {}).redirectWithData) {
      setLoading(true)
      Axios({
        method: "get",
        url: `${endpoint}/${jobId}`,
        params: { id: jobId },
      })
        .then(({ data }) => {
          setData(data)
          setLoading(false)
        })
        .catch(({ response }) => {
          setError({
            title: "Record Not Found",
            message:
              "The Vacancy you are trying see is not avialble, may be closed or not valid anymore",
            redirectBack: "/",
          })
          setLoading(false)
          console.error(
            ((response || {}).data || {}).errors || "Error in loading data",
          )
        })
    }

    if (data) {
      isUserApplied()
    }
  }, [endpoint, jobId, location]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!data) return null;

  const { request: position } = data

  if (loading) {
    return <Loading/>
  }

  if (Object.keys(error).length) {
    return (
      <Container>
        <Error
          title={error.title}
          message={error.message || "Error"}
          redirectBack={error.redirectBack || "/"}
        />
      </Container>
    )
  }

  if (Object.keys(data).length) {
    return (
      <>
        <div className="container py-5">
          <Row>
            <Col xs={12} sm={8}>
              <SEO
                title={
                  (position || {}).position_requested ||
                  (position || {}).position_id_display ||
                  "Untitled Position"
                }
              />
              <h2 className="mb-2 mb-md-4 text-blue">
                <strong>
                  {(position || {}).position_requested ||
                  (position || {}).position_id_display ||
                  "Untitled Position"}
                  {` `}
                </strong>
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: (data || {}).career_portal_description,
                }}
              />
              {(isApplied || {}).status === true ? (
                <Badge
                  size="lg"
                  variant="warning"
                  className="fs-150 btn-d-border round mt-4 p-3"
                  style={{ display: "block" }}
                >
                  You've already Applied for this position
                </Badge>
              ) : (
                <>
                  {(state || {}).checkLogin() ? (
                    <Button
                      color="primary"
                      onClick={handleOpen}
                      className="btn-lg mt-4"
                    >
                      Apply Online
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className="btn-lg mt-4 text-white"
                    >
                      <Link
                        to="/login"
                        className="text-white"
                        state={{
                          redirect: `/jobs/${jobId}`,
                        }}
                      >
                        Login to Apply
                      </Link>
                    </Button>
                  )}
                </>
              )}
            </Col>
            <Col xs={12} sm={4}>
              {(((data || {}).request || {}).h_c_m_organization || {})
                .swan_orgid ? (
                <div
                  style={{
                    width: "60%",
                    padding: "1rem 5rem",
                    textAlign: "center",
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={`https://s3.ap-southeast-1.amazonaws.com/assets.swan.mazrui.ae/Administration/CorporateIdentity/${data.request.h_c_m_organization.swan_orgid}/LOGO.jpg`}
                  />
                </div>
              ) : null}
              {(position || {}).position_requirements_key_qualities ? (
                <>
                  <h3 className="my-4"><strong>Key Qualities</strong></h3>
                  {(position || {}).position_requirements_key_qualities}
                </>
              ) : null}
              {(position || {})
                .position_requirements_principle_accountabilities ? (
                <>
                  <h3 className="my-4"><strong> Principal Accountabilites</strong></h3>
                  {
                    (position || {})
                      .position_requirements_principle_accountabilities
                  }
                </>
              ) : null}
              {(position || {})
                .position_requirements_principle_accountabilities ? (
                <>
                  <h3 className="my-4"><strong> Principal Accountabilites</strong></h3>
                  {
                    (position || {})
                      .position_requirements_principle_accountabilities
                  }
                </>
              ) : null}

              {(position || {}).position_requirements_qualifications ? (
                <>
                  <h3 className="my-4"><strong>Requirements</strong></h3>
                  {(position || {}).position_requirements_qualifications}
                </>
              ) : null}
              {(position || {}).position_placement_type ? (
                <>
                  <h3 className="my-4"><strong>Placement Type</strong></h3>
                  {(position || {}).position_placement_type}
                </>
              ) : null}
              {(position || {}).hcm_orgid_display ? (
                <>
                  <h3 className="my-4"><strong>Organization</strong></h3>
                  {(position || {}).hcm_orgid_display}
                </>
              ) : null}
              {(position || {}).department_id_display ? (
                <>
                  <h3 className="my-4"><strong>Department</strong></h3>
                  {(position || {}).department_id_display}
                </>
              ) : null}
              {(position || {}).position_location_id_display ? (
                <>
                  <h3 className="my-4"><strong>Location</strong></h3>
                  {(position || {}).position_location_id_display}
                </>
              ) : null}

            </Col>
          </Row>
        </div>

        {
          isOpen ? (
            <ApplicantForm
              jobId={jobId}
              onClose={handleClose}
              isOpen={isOpen}
              apiURL={apiURL}
            />
          ) : null
        }
      </>
    )
  }

  return null
}

export default withState(JobSingle)
