import React from "react"

export default () => (
  <div className="ripple-wrapper">
    <div className="ripple">
      <div></div>
      <div></div>
    </div>
  </div>
)
