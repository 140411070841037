import React, { useEffect, useState, useCallback } from "react"
import { Modal, Button, Form, Col } from "react-bootstrap"

import Axios from "axios"
import { navigate } from "gatsby"

import Uploader from "../forms/uploader"
import Error from "./error"
import Loading from "./loading"
import Alert from "../alert"
import withState from "../../util/withState"
import { onRequest } from "../../util/service"

const ApplicantForm = ({
                         jobId,
                         isOpen,
                         onClose,
                         state,
                         apiURL,
                         validate,
                         onSuccessApply,
                         onSuccessRedirect,
                       }) => {
  const { state: stateData, setState } = state
  // const [uploads, doUpload] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const [isOpenModel, setIsOpenModel] = useState(isOpen)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [formErrors, setFormError] = useState({
    submitting: false,
    status: null,
  })

  const onDrop = useCallback(acceptedFiles => {
    setUploadedFiles(acceptedFiles)
  }, [])

  useEffect(() => {
    if (stateData.metaReady === false) {
      setLoading(true)
      onRequest({
        endpoint: `${apiURL}/meta`,
        onSuccess: data => {
          setState({
            ...stateData,
            ...data,
            metaReady: true,
          })
        },
        onFailed: error => {

          setError({
            // title: "Error in loading form Data",
            message: (error || {}).data.message ||
              "There is something went wrong, please try logout and login back again",
            doAskLogout: false,
          })
        },
        onAnyCase: () => {
          setLoading(false)
        },
      })
    }
  }, [jobId, apiURL, setState, stateData])

  const handleServerResponse = (ok, msg, form) => {
    setFormError({
      submitting: false,
      status: { ok, msg },
    })
  }

  const handleOnApply = e => {
    e.preventDefault()
    const formElement = e.target
    const formData = new FormData(formElement)
    formData.append("vacancy_id", jobId)
    uploadedFiles.forEach((uploadedFile, i) => {
      formData.append(`upload_${i}`, uploadedFile, uploadedFile.name)
    })

    // @todo validation, not yet used any validation implementation

    setFormError({ ...formErrors, submitting: true })
    Axios({
      method: "post",
      url: `${apiURL}/candidates`,
      data: formData,
    })
      .then(({ data }) => {
        if (onSuccessApply && onSuccessApply instanceof Function) {
          onSuccessApply(data)
        }

        if (onSuccessRedirect) {
          if (typeof window !== "undefined") {
            navigate(onSuccessRedirect, {
              redirectedFrom: `${apiURL}/jobs/${jobId}`,
            })
          }
        }

        handleServerResponse(true, "Applied!", formElement)
        onClose()
      })
      .catch(({ error }) => {
        handleServerResponse(false, error, formElement)
      })
  }

  const toggle = () => {
    setIsOpenModel(!isOpenModel)
  }

  if (loading) {
    return <Loading/>
  }

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      toggle={toggle}
      backdrop="static"
      dialogClassName="modal-90w"
    >
      {Object.keys(error).length ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Something Went Wrong</Modal.Title>
          </Modal.Header>
          <Error {...error} />
        </>
      ) : (
        <>
          {loading ||
          !(stateData || {}).metaReady ||
          stateData.metaReady === false ? (
            <Loading/>
          ) : (
            <>
              <Modal.Header closeButton>
                <Modal.Title>You are applying for #{jobId}</Modal.Title>
              </Modal.Header>
              <Form
                className="bg-white round-font"
                encType="multipart/form-data"
                onSubmit={handleOnApply}
              >
                <Modal.Body>
                  {/* errors */}
                  {((formErrors || {}).status || {}).ok === false ? (
                    <Alert
                      type="danger"
                      title="Error in Applying to this Position"
                      message={((formErrors || {}).status || {}).msg}
                    />
                  ) : null}

                  <fieldset disabled={(formErrors || {}).submitting === true}>
                    {/* Person name */}
                    <Form.Row>
                      <Form.Group as={Col} md={3} controlId="candidate.name">
                        <Form.Label>Title</Form.Label>
                        <Form.Control as="select" name="title_id">
                          <option value="">Select Title</option>
                          {(stateData || {}).titles.map(title => (
                            <option key={title.id} value={title.id}>
                              {title.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} controlId="candidate.name">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="first_name"
                          required
                          placeholder="Enter First Name"
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="candidate.name">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="last_name"
                          required
                          placeholder="Enter Last Name"
                        />
                      </Form.Group>
                    </Form.Row>
                    {/* Person gender and age */}
                    <Form.Row>
                      <Form.Group as={Col} controlId="candidate.data">
                        <Form.Label>Gender</Form.Label>
                        <Form.Control as="select" name="gender_id" required>
                          <option value="">Select Title</option>
                          {(stateData || {}).genders.map(gender => (
                            <option key={gender.id} value={gender.id}>
                              {gender.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} controlId="candidate.data">
                        <Form.Label>Marital Status</Form.Label>
                        <Form.Control
                          as="select"
                          name="marital_status_id"
                          required
                        >
                          <option value="">Select Marital Status</option>
                          {(stateData || {}).marital_statuses.map(
                            maritalStatus => (
                              <option
                                key={maritalStatus.id}
                                value={maritalStatus.id}
                              >
                                {maritalStatus.name}
                              </option>
                            ),
                          )}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} controlId="candidate.data">
                        <Form.Label>Nationality</Form.Label>
                        <Form.Control
                          as="select"
                          name="nationality_id"
                          required
                        >
                          <option value="">Select Nationality</option>
                          {(stateData || {}).nationalities.map(nationality => (
                            <option key={nationality.id} value={nationality.id}>
                              {nationality.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>


                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="candidate.data">
                        <Form.Label>Religion</Form.Label>
                        <Form.Control as="select" name="religion_id" required>
                          <option value="">Select Religion</option>
                          {(stateData || {}).religions.map(religion => (
                            <option key={religion.id} value={religion.id}>
                              {religion.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} controlId="candidate.data">
                        <Form.Label>Date Of Birth</Form.Label>
                        <Form.Control
                          type="date"
                          name="dob"
                          required
                          placeholder="Enter Date of Birth"
                        />
                      </Form.Group>
                    </Form.Row>
                    {/*  */}

                    <Form.Row>
                      <Form.Group as={Col} controlId="candidate.contact">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="text"
                          name="contact_phone"
                          required
                          placeholder="Enter Contact Phone"
                          defaultValue="+971"
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="candidate.contact">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          required
                          placeholder="Enter Email"
                          defaultValue={
                            ((stateData || {}).user || {}).email || undefined
                          }
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Uploader onDrop={onDrop}/>
                    </Form.Row>
                  </fieldset>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-d-border"
                    // block
                  >
                    Apply
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={onClose}
                    className="btn-d-border round"
                    // block
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </>
      )}
    </Modal>
  )
}

export default withState(ApplicantForm)
