import React from "react"
import { Container, Card } from "react-bootstrap"
import { useDropzone } from "react-dropzone"

const Uploader = ({ onDrop }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf, application/vnd.ms-powerpoint, pplication/vnd.openxmlformats-officedocument.presentationml.presentation, application/rtf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv",
  })

  const files = acceptedFiles.map(file => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    )
  })

  return (
    <Container>
      <Card
        bg="secondary"
        {...getRootProps({ className: "dropzone text-center" })}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <input {...getInputProps()} />
        <p
          className="mb-0"
          style={{
            fontWeight: "bold",
            color: "white",
            padding: "1rem",
          }}
        >
          Upload your Resume/CV and Supporting documents
        </p>
      </Card>
      {acceptedFiles.length ? (
        <aside>
          <ul>{files}</ul>
        </aside>
      ) : null}
    </Container>
  )
}

export default Uploader
